// config.js

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const wsEndpoint = process.env.REACT_APP_WS_ENDPOINT;
export default {
    apiBaseUrl: `${apiEndpoint}/api`,
    sectorUrl: `${apiEndpoint}/api/sectors/?format=json`,
    sectorListUrl: `${apiEndpoint}/api/sectors/list?format=json`,
    sectorNestedDetailsUrl: `${apiEndpoint}/api/nested-sectors/?format=json`,
    jobRolesUrl: `${apiEndpoint}/api/jobrole/?format=json`,
    competenciesUrl: `${apiEndpoint}/api/competencies/?format=json`,
    skillsUrl: `${apiEndpoint}/api/skills/?format=json`,
    assessmentsUrl: `${apiEndpoint}/api/generate-assessment/`,
    assessmentsListURL: `${apiEndpoint}/api/assessments/?format=json`,
    transcribe: `${apiEndpoint}/api/transcribe/`,
    skillsAnalysisUrl: `${apiEndpoint}/api/skills/analysis/`,
    chatUrl: `${apiEndpoint}/api/chat/`,
    frameworkUrl: `${apiEndpoint}/api/frameworks/?format=json`,
    addFrameworkUrl: `${apiEndpoint}/api/frameworks/`,
    uploadFrameworkDetailsUrl: `${apiEndpoint}/api/frameworks/upload/`,
    agentUrl: `${wsEndpoint}/ws/agent/`,
    saveToVectorDBUrl: `${apiEndpoint}/api/save-to-vector-db/`,
    voteUrl: `${apiEndpoint}/api/vote/`,
    promptsUrl: `${apiEndpoint}/api/prompts/`,
    deletePromptsUrl: `${apiEndpoint}/api/delete_message/`,
    profilesListUrl: `${apiEndpoint}/api/user-profiles/by-organisation/{organisation_id}/`,
    profileUrl: `${apiEndpoint}/api/user-profiles/by-ext-user-id/{ext_user_id}/`,
    updateUserProfileUrl:  `${apiEndpoint}/api/user-profiles/{id}/`,
    uploadFilesUrl: `${apiEndpoint}/api/files/upload/`,
    deleteFilesUrl: `${apiEndpoint}/api/files/delete/`,
    saveUsersToVectorDBUrl: `${apiEndpoint}/api/save_users_to_vector_db`,
    updateUserUrl: `${apiEndpoint}/api/user-profiles/add-or-update-by-email/`,
    organisationUrl: `${apiEndpoint}/api/organisations/`,
    rubricsUrl: `${apiEndpoint}/api/rubrics/`,
    listTypeUrl: `${apiEndpoint}/api/list-types/`,
    releaseLogUrl: `${apiEndpoint}/api/release-logs/`,
    recruitmentListUrl: `${apiEndpoint}/api/recruitments/`,
    addRecruitmentUrl: `${apiEndpoint}/api/recruitments/`

};
