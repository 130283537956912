import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Layout, Menu, Modal } from 'antd';
import { BrowserRouter as Router, Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomHeader from './components/Header';
import Joyride from 'react-joyride'; // Import react-joyride
import CreateAssessmentPage from './components/CreateAssessmentPage';
import ReviewAssessmentQuestions from './components/ReviewAssessmentQuestions';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import AssessmentListingPage from "./components/AssessmentListingPage";
import Transcribe from "./components/Transcribe/Transcribe";
import AnalyseSkills from "./pages/AnalyseSkills";
import SkillGap from "./components/SkillGap";
import FrameworkDetails from "./components/FrameworkDetails";
import InteractiveAgent from "./pages/InteractiveAgent";
import Framework from "./pages/Framework";
import NotFound from './components/NotFound';
import UserDirectory from "./pages/UserDirectory";
import Organisation from "./pages/organisation/Organisation";
import RubricsManager from "./components/Rubrics/RubricsManager";
import ReleaseLogTimeline from './components/ReleaseLogTimeline';
import RecruitmentManager from './pages/recruitment/RecruitmentManager'
import './i18n/i18n';
import config from "./config";
import './styles/ReleaseLog.scss';
import { BarChartOutlined, InteractionOutlined, LineChartOutlined, PieChartOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const App = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || 'null'));
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile') || 'null'));
    const [collapsed, setCollapsed] = useState(false);
    const [defaultPage, setDefaultPage] = useState(null);
    const [profileFetched, setProfileFetched] = useState(false);
    const [showReleaseLog, setShowReleaseLog] = useState(false); // Track whether to show release log
    const [runTutorial, setRunTutorial] = useState(false); // Track whether to run the tutorial

    // Tutorial Steps
    const tutorialSteps = [
        {
            target: '.skills-scorecard',
            content: 'Check your skills scorecard here.',
        },
        {
            target: '.release-log-container',
            content: 'View recent release logs here.',
        },
    ];

    const login = useGoogleLogin({
        onSuccess: async (response) => {
            localStorage.setItem('access_token', response.access_token);
            localStorage.setItem('user', JSON.stringify(response));
            setUser(response);

            // Fetch Google user profile details
            const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`);
            const { id, given_name, family_name, email } = res.data;

            // Store Google profile in localStorage
            setProfile(res.data);
            localStorage.setItem('profile', JSON.stringify(res.data));

            // Call the backend API to create/update the user
            try {
                const updateUserResponse = await axios.post(config.updateUserUrl, {
                    ext_user_id: id,
                    ext_first_name: given_name,
                    ext_last_name: family_name,
                    ext_email: email,
                });
                console.log("User updated successfully:", updateUserResponse.data);

                // Check if there are unseen releases and show the ReleaseLogTimeline
                checkForUnseenReleases();
                // Start the tutorial after successful login
                startTutorial();
            } catch (error) {
                console.error('Error updating user:', error);
            }

            if (id === '108329022040194861162') {
                setDefaultPage('/skills-gap-current');
            } else {
                setDefaultPage('/directory');
            }
            setProfileFetched(true);
        },
        onFailure: (error) => {
            console.error('Login failed:', error);
        },
    });

    const logout = () => {
        googleLogout();
        setUser(null);
        localStorage.removeItem('user');
        setProfile(null);
        localStorage.removeItem('profile');
        window.location.href = '/';
    };

    // Start tutorial
    const startTutorial = () => {
        const tutorialCompleted = localStorage.getItem('tutorialCompleted');
        if (!tutorialCompleted) {
            setRunTutorial(true); // Start the tutorial
        }
    };

    // Check for unseen release logs
    const checkForUnseenReleases = async () => {
        try {
            const response = await axios.get(`${config.apiBaseUrl}/release-logs`);
            const logs = response.data;
            const unseenLogs = logs.filter(log => {
                const seenReleases = JSON.parse(localStorage.getItem('seenReleases')) || [];
                return !seenReleases.includes(log.id);
            });

            if (unseenLogs.length > 0) {
                setShowReleaseLog(true); // Show the release log modal if there are unseen releases
            }
        } catch (error) {
            console.error('Error fetching release logs:', error);
        }
    };

    useEffect(() => {
        if (profile) {
            if (profile.id === '108329022040194861162') {
                setDefaultPage('/skills-gap-current');
            } else {
                setDefaultPage('/directory');
            }
            setProfileFetched(true);
        }

        const fetchProfile = async (token) => {
            try {
                const bearerToken = `Bearer ${token}`;
                const config = {
                    headers: {
                        Authorization: bearerToken,
                        Accept: 'application/json',
                    },
                };
                const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, config);
                setProfile(res.data);
                localStorage.setItem('profile', JSON.stringify(res.data));

                if (res.data.id === '108329022040194861162') {
                    setDefaultPage('/skills-gap-current');
                } else {
                    setDefaultPage('/directory');
                }
                setProfileFetched(true);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    console.error('Token expired or invalid, redirecting to login...');
                    setDefaultPage('/');
                } else {
                    console.error(err);
                }
            }
        };

        if (user && !profileFetched) {
            const token = user.access_token;
            fetchProfile(token).catch(err => {
                console.error('Error fetching profile:', err);
            });
        }

        const handleStorageChange = (event) => {
            if (event.key === 'user') {
                const newUser = JSON.parse(event.newValue);
                setUser(newUser);
                if (newUser) {
                    fetchProfile(newUser.access_token).catch(err => console.error('Error fetching profile on storage change:', err));
                } else {
                    setProfile(null);
                    setDefaultPage('/');
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [user, profile, profileFetched]);

    const menuConfig = {
        '108329022040194861162': ['dashboard', 'interactiveAgent'],
        'default': ['dashboard', 'skillsScoreCard', 'interactiveAgent', 'skillsLandscape', 'utilities', 'admin']
    };

    const userMenuConfig = profile?.id ? menuConfig[profile.id] || menuConfig.default : menuConfig.default;

    const items = [
        {
            key: 'dashboard',
            label: t('menu.dashboard'),
            icon: <LineChartOutlined />,
            children: [
                {
                    key: '/skills-gap-current',
                    label: profile ? (
                        <Link to="/skills-gap" state={{ type: 'current', profileId: profile.id }}>
                            {t('menu.my_scorecard')}
                        </Link>
                    ) : (
                        t('menu.my_scorecard')
                    ),
                },
                {
                    key: '/skills-gap-desired',
                    label: profile ? (
                        <Link to="/skills-gap" state={{ type: 'desired', profileId: profile.id }}>
                            {t('menu.desired_role_scorecard')}
                        </Link>
                    ) : (
                        t('menu.desired_role_scorecard')
                    ),
                },
            ],
        },
        {
            key: 'skillsScoreCard',
            label: t('menu.skills_scorecard'),
            icon: <PieChartOutlined />,
            children: [
                {
                    key: '/directory',
                    label: <Link to="/directory">{t('menu.users')}</Link>,
                },
            ],
        },
        {
            key: 'interactiveAgent',
            label: (
                <Link to="/interactive-agent">
                    {t('menu.interactive_agent')}
                </Link>
            ),
            icon: <InteractionOutlined />,
        },
        {
            key: 'skillsLandscape',
            label: t('menu.skills_landscape'),
            icon: <LineChartOutlined />,
            children: [
                {
                    key: '/framework',
                    label: <Link to="/framework">{t('menu.skillsFramework')}</Link>,
                },
                {
                    key: '/generate',
                    label: <Link to="/generate">{t('menu.createAssessments')}</Link>,
                },
                {
                    key: '/list',
                    label: <Link to="/list">{t('menu.assessments')}</Link>,
                },
            ],
        },
        {
            key: 'utilities',
            label: t('menu.utilities'),
            icon: <BarChartOutlined />,
            children: [
                {
                    key: '/transcribe',
                    label: <Link to="/transcribe">{t('menu.transcribe')}</Link>,
                },
                {
                    key: '/stapp',
                    label: (
                        <a href="/stapp/" target="_blank" rel="noopener noreferrer">
                            {t('menu.cvScreener')}
                        </a>
                    )
                },
            ],
        },
        {
            key: 'admin',
            label: t('menu.admin'),
            icon: <LineChartOutlined />,
            children: [
                {
                    key: '/organisation',
                    label: <Link to="/organisation">{t('menu.orgManagement')}</Link>
                },
                {
                    key: '/rubrics',
                    label: <Link to="/rubrics">{t('menu.rubrics')}</Link>
                }
            ]
        }
    ].filter(item => userMenuConfig.includes(item.key) || item.children?.some(child => userMenuConfig.includes(child.key)));

    const SideNav = () => {
        const location = useLocation();

        const selectedKeys = items
            .flatMap(item => item.children ? item.children.map(child => child.key) : item.key)
            .filter(key => location.pathname.startsWith(key));

        return (
            <Sider className="light-slider" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Menu mode="inline" selectedKeys={selectedKeys} items={items} />
            </Sider>
        );
    };

    // Render the Release Log Modal
    const renderReleaseLogModal = () => (
        <Modal
            open={showReleaseLog}
            title={t('release_log.title')}
            footer={null}
            onCancel={() => setShowReleaseLog(false)}
            width={800}
        >
            <ReleaseLogTimeline />
        </Modal>
    );

    return (
        <Router>
            <Layout className="layout">
                {/* Joyride Component for the Guided Tutorial */}
                <Joyride
                    steps={tutorialSteps}
                    run={runTutorial}
                    continuous
                    showProgress
                    showSkipButton
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                    callback={(data) => {
                        if (data.status === 'finished') {
                            setRunTutorial(false);
                            localStorage.setItem('tutorialCompleted', 'true');
                        }
                    }}
                    locale={{
                        back: 'Back',
                        close: 'Close',
                        last: 'Finish',
                        next: 'Next',
                        skip: 'Skip',
                    }}
                />

                <CustomHeader className="custom-header" profile={profile} login={login} logout={logout} />
                {user ? (
                    defaultPage ? (
                        <Layout>
                            {renderReleaseLogModal()} {/* Render the release log modal if applicable */}
                            <SideNav />
                            <Layout>
                                <Routes>
                                    <Route path="/" element={<Navigate to={defaultPage} replace />} />
                                    <Route path="/skills-gap-current" element={<Navigate to="/skills-gap" state={{ type: 'current', profileId: profile?.id }} replace />} />
                                    <Route path="/skills-gap-desired" element={<Navigate to="/skills-gap" state={{ type: 'desired', profileId: profile?.id }} replace />} />
                                    <Route path="/framework" element={<Framework />} />
                                    <Route path="/list" element={<AssessmentListingPage />} />
                                    <Route path="/generate" element={<CreateAssessmentPage />} />
                                    <Route path="/directory" element={<UserDirectory />} />
                                    <Route path="/skills-gap" element={<SkillGap />} />
                                    <Route path="/review-assessment-questions" element={<ReviewAssessmentQuestions />} />
                                    <Route path="/analyse-skills" element={<AnalyseSkills profile={profile} />} />
                                    <Route path="/transcribe" element={<Transcribe profile={profile} />} />
                                    <Route path="/framework-details/:id" element={<FrameworkDetails />} />
                                    <Route path="/interactive-agent" element={<InteractiveAgent profile={profile} agent_type='general' />} />
                                    <Route path="/organisation" element={<Organisation />} />
                                    <Route path="/rubrics" element={<RubricsManager />} />
                                    <Route path="/recruiter" element={<RecruitmentManager profile={profile} />} />
                                    {/*<Route path="/recruiter" element={<InteractiveAgent profile={profile} agent_type='recruiter' />} />*/}
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Layout>
                        </Layout>
                    ) : (
                        <div>Loading...</div>
                    )
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <Button type="primary" onClick={login}>
                            {t('sign_in')}
                        </Button>
                    </div>
                )}
            </Layout>
        </Router>
    );
};

export default App;
