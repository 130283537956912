import React from 'react';
import { Table, Dropdown, Menu, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const RubricsList = ({ rubrics, onEditRubric }) => {
    const { t } = useTranslation();  // Translation hook

    // Helper function to calculate rowSpan for merging rubric cells
    const calculateRowSpan = (rubric) => {
        const metricsCount = rubric.metrics.length;
        return metricsCount > 1 ? metricsCount : 1;  // Merge if more than 1 metric
    };

    // Create a data array that "flattens" rubrics and metrics for the table
    const flattenRubricsData = () => {
        const flatData = [];

        rubrics.forEach((rubric) => {
            rubric.metrics.forEach((metric, index) => {
                flatData.push({
                    key: `${rubric.id}-${metric.id}`,  // Unique key for each row
                    rubric,  // Include the whole rubric object for rowSpan calculations
                    metric,
                    isFirstMetric: index === 0  // Mark the first metric to handle rowSpan for rubrics
                });
            });
        });

        return flatData;
    };

    // Menu for edit action
    const menu = (rubric) => (
        <Menu>
            <Menu.Item key="edit" onClick={() => onEditRubric(rubric)}>
                {t('rubrics.edit')}
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: t('rubrics.rubric_title'),
            dataIndex: ['rubric', 'title'],
            key: 'title',
            render: (text, record) => ({
                children: text,
                props: {
                    rowSpan: record.isFirstMetric ? calculateRowSpan(record.rubric) : 0  // Merge cells for rubric title
                }
            })
        },
        {
            title: t('rubrics.rubric_type'),
            dataIndex: ['rubric', 'type', 'value'],  // Access nested type.value
            key: 'type',
            render: (text, record) => ({
                children: text,
                props: {
                    rowSpan: record.isFirstMetric ? calculateRowSpan(record.rubric) : 0  // Merge cells for rubric type
                }
            })
        },
        {
            title: t('rubrics.weightage'),
            dataIndex: ['rubric', 'weightage'],
            key: 'weightage',
            render: (text, record) => ({
                children: text,
                props: {
                    rowSpan: record.isFirstMetric ? calculateRowSpan(record.rubric) : 0  // Merge cells for weightage
                }
            })
        },
        {
            title: t('rubrics.metric_name'),
            dataIndex: ['metric', 'name'],
            key: 'metric_name',
        },
        {
            title: t('rubrics.metric_description'),
            dataIndex: ['metric', 'description'],
            key: 'metric_description',
        },
        {
            title: t('rubrics.metric_score'),
            dataIndex: ['metric', 'score'],
            key: 'metric_score',
        },
        {
            title: t('rubrics.metric_comments'),
            dataIndex: ['metric', 'comments'],
            key: 'metric_comments',
        },
        {
            title: t('actions'),
            key: 'actions',
            render: (text, record) => (
                <Dropdown overlay={menu(record.rubric)}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            )
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={flattenRubricsData()}
            pagination={false}  // Disable pagination for this table
            rowKey="key"  // Ensure each row has a unique key
            style={{ width: '100%' }}  // Increase table container width
        />
    );
};

export default RubricsList;
