import React, { useState } from 'react';
import { Upload, message, Progress, Typography, Button } from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import config from '../config';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;
const { Text } = Typography;

const FileUpload = ({
                        accept = '*',
                        multiple = false,
                        directory = false,
                        onUploadSuccess,  // This will be called when file upload is successful
                        onUploadError,
                        dragArea = true,
                        component,
                        component_id,
                        user_id,
                        purpose,
                    }) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [overallProgress, setOverallProgress] = useState(0);
    const [showDetails, setShowDetails] = useState(false);

    const handleUpload = async (options) => {
        const { file, onSuccess, onError } = options;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('content_type', file.type);
        formData.append('filename', file.name);
        formData.append('size', file.size);
        formData.append('ext_user_id', user_id);
        formData.append('purpose', purpose);

        if (component && component_id) {
            formData.append('component', component);
            formData.append('component_id', component_id);
        }

        try {
            const response = await axios.post(config.uploadFilesUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (event) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);

                    setFileList(prevFileList => {
                        const updatedFileList = prevFileList.map((item) => {
                            if (item.uid === file.uid) {
                                return { ...item, percent };
                            }
                            return item;
                        });

                        const totalProgress = updatedFileList.reduce((acc, curr) => acc + (curr.percent || 0), 0) / updatedFileList.length;
                        setOverallProgress(Math.floor(totalProgress));

                        return updatedFileList;
                    });
                },
            });

            if (response && response.data && response.data.files) {
                const uploadedFileId = response.data.files[0];  // Get the file ID
                if (onUploadSuccess) {
                    onUploadSuccess(uploadedFileId);  // Call the onUploadSuccess prop
                }
                onSuccess(uploadedFileId);  // Call success for Ant Design's Upload component
            }

            setFileList((prevFileList) => {
                const updatedFileList = prevFileList.map((item) => {
                    if (item.uid === file.uid) {
                        return { ...item, status: 'done' };
                    }
                    return item;
                });
                return updatedFileList;
            });

            message.success(`${file.name} file uploaded successfully.`);
        } catch (error) {
            onError(error);
            onUploadError && onUploadError(error);
            setFileList((prevFileList) => prevFileList.map((item) => (item.uid === file.uid ? { ...item, status: 'error' } : item)));
            message.error(`${file.name} file upload failed.`);
        }
    };

    const handleChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
    };

    const handleRemoveFile = (uid) => {
        setFileList(prevFileList => prevFileList.filter(file => file.uid !== uid));
        message.success(t('uploader.removeFileSuccess'));
    };

    const renderProgress = () => {
        const completedFiles = fileList.filter(file => file.status === 'done').length;
        const totalFiles = fileList.length;

        const uploading = completedFiles < totalFiles;
        const progressText = uploading
            ? t('uploader.uploading', { completed: completedFiles, total: totalFiles })
            : t('uploader.uploaded', { completed: completedFiles, total: totalFiles });

        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                <Progress type="circle" percent={overallProgress} size={35} />
                <Text style={{ marginLeft: 16 }}>{progressText}</Text>
                <Button type="link" onClick={() => setShowDetails(!showDetails)} style={{ marginLeft: 16 }}>
                    {showDetails ? t('uploader.hideDetails') : t('uploader.viewDetails')}
                </Button>
            </div>
        );
    };

    const renderDetails = () => {
        if (!showDetails) return null;

        return (
            <div style={{ marginTop: 16 }}>
                {fileList.map(file => (
                    <div key={file.uid} style={{ marginTop: 8, display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ flex: 3, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            <Text>{file.name}</Text>
                        </div>
                        <div style={{ flex: 2, marginLeft: 16 }}>
                            <Progress percent={file.percent} size="small" style={{ width: '100%' }} />
                        </div>
                        {file.status === 'done' && (
                            <DeleteOutlined
                                onClick={() => handleRemoveFile(file.uid)}
                                style={{ color: 'red', marginLeft: 16, cursor: 'pointer' }}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            {dragArea ? (
                <Dragger
                    accept={accept}
                    multiple={multiple}
                    directory={directory}
                    customRequest={handleUpload}
                    fileList={fileList}
                    onChange={handleChange}
                    showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('uploader.clickOrDrag')}</p>
                </Dragger>
            ) : (
                <Upload
                    accept={accept}
                    multiple={multiple}
                    directory={directory}
                    customRequest={handleUpload}
                    fileList={fileList}
                    onChange={handleChange}
                    showUploadList={false}
                >
                    <Button>{t('uploader.browse')}</Button>
                </Upload>
            )}
            {fileList.length > 0 && (
                <div style={{ marginTop: 16 }}>
                    {renderProgress()}
                    {renderDetails()}
                </div>
            )}
        </>
    );
};

FileUpload.propTypes = {
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    directory: PropTypes.bool,
    onUploadSuccess: PropTypes.func,
    onUploadError: PropTypes.func,
    dragArea: PropTypes.bool,
    component: PropTypes.string,
    component_id: PropTypes.string,
    user_id: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
};

export default FileUpload;
