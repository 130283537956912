import React from 'react';
import { Table, Dropdown, Menu, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { MoreOutlined } from '@ant-design/icons';

const RecruitmentList = ({ data, onEdit, onViewCandidates }) => {
    const { t } = useTranslation();

    const handleMenuClick = (record, action) => {
        if (action.key === 'edit') {
            onEdit(record);
        } else if (action.key === 'viewCandidates') {
            onViewCandidates(record);
        }
    };

    const columns = [
        {
            title: t('recruitment.job_role'),
            dataIndex: 'job_role',  // Match the API field 'job_role'
            key: 'job_role',
        },
        {
            title: t('recruitment.start_date'),
            dataIndex: 'start_date',  // Match the API field 'start_date'
            key: 'start_date',
        },
        {
            title: t('recruitment.end_date'),
            dataIndex: 'end_date',  // Match the API field 'end_date'
            key: 'end_date',
        },
        {
            title: t('recruitment.num_candidates'),
            dataIndex: 'numberOfCandidates',  // This may or may not be present
            key: 'numberOfCandidates',
            render: (value) => (value !== undefined ? value : ''),  // Render empty string if no value
        },
        {
            title: t('recruitment.shortlisted_candidates'),
            dataIndex: 'shortlistedCandidates',  // This may or may not be present
            key: 'shortlistedCandidates',
            render: (value) => (value !== undefined ? value : ''),  // Render empty string if no value
        },
        {
            title: t('actions'),
            key: 'actions',
            render: (text, record) => {
                const menu = (
                    <Menu onClick={(action) => handleMenuClick(record, action)}>
                        <Menu.Item key="edit">
                            {t('edit')}
                        </Menu.Item>
                        <Menu.Item key="viewCandidates">
                            {t('recruitment.view_candidates')}
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu}>
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    return <Table columns={columns} dataSource={data} rowKey="id" />;
};

export default RecruitmentList;
