import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Spin, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import RubricsList from './RubricsList';
import RubricForm from './RubricForm';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import config from "../../config";
import '../../styles/App.scss';
import '../../styles/RubricsManager.scss';

const RubricsManager = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [rubrics, setRubrics] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentRubric, setCurrentRubric] = useState(null);  // To track the rubric being edited

    useEffect(() => {
        // Fetch existing rubrics
        axios.get(`${config.rubricsUrl}`)
            .then(response => {
                setRubrics(response.data);
                setLoading(false);
            })
            .catch(error => {
                message.error(t('rubrics.rubrics_fetch_error'));
                setLoading(false);
                console.log(error);
            });
    }, [t]);

    const handleAddNewRubric = () => {
        setCurrentRubric(null);  // Clear the current rubric to create a new one
        setIsModalVisible(true);
    };

    const handleEditRubric = (rubric) => {
        setCurrentRubric(rubric);  // Load the selected rubric into the form
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleFormSubmit = (rubricData) => {
        if (currentRubric) {
            // Updating an existing rubric
            axios.put(`${config.rubricsUrl}${currentRubric.id}/`, rubricData)
                .then(() => {
                    message.success(t('rubrics.rubric_updated_success'));
                    setIsModalVisible(false);
                    // Reload the updated rubric data
                    setRubrics(rubrics.map(r => (r.id === currentRubric.id ? { ...r, ...rubricData } : r)));
                })
                .catch(() => {
                    message.error(t('rubrics.rubric_update_error'));
                });
        } else {
            // Creating a new rubric
            axios.post(`${config.rubricsUrl}`, rubricData)
                .then(response => {
                    setRubrics([...rubrics, response.data]);
                    message.success(t('rubrics.rubric_added_success'));
                    setIsModalVisible(false);
                })
                .catch(() => {
                    message.error(t('rubrics.rubric_add_error'));
                });
        }
    };

    return (
        <Spin spinning={loading}>
            <Card className="rubrics-manager-card" style={{ width: '100%' }}>
                <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
                    <h2>{t('rubrics.rubrics_manager')}</h2>
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNewRubric}>
                        {t('rubrics.add_new_rubric')}
                    </Button>
                </div>

                <div className="rubrics-list-container" style={{ width: '100%' }}>
                    <RubricsList rubrics={rubrics} onEditRubric={handleEditRubric} />
                </div>

                <Modal
                    title={currentRubric ? t('rubrics.edit_rubric') : t('rubrics.add_new_rubric')}
                    visible={isModalVisible}
                    onCancel={handleModalCancel}
                    footer={null}
                    width={980}  // Set the modal width to be wider
                >
                    <RubricForm rubric={currentRubric} onSubmit={handleFormSubmit} />
                </Modal>
            </Card>
        </Spin>
    );
};

export default RubricsManager;
