import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Spin, Card, message, Dropdown, Menu, Button, Modal, Form, Select, Progress, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EllipsisOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons';
import config from "../config";
import '../styles/App.scss';

const { Option } = Select;
const { Dragger } = Upload;

const UserDirectory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [analysisLoading, setAnalysisLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [currentJobRoles, setCurrentJobRoles] = useState([]);
    const [desiredJobRoles, setDesiredJobRoles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [additionalFiles, setAdditionalFiles] = useState([]); // New state for additional files
    const [uploadProgress, setUploadProgress] = useState({}); // Track upload progress
    const [currentSector, setCurrentSector] = useState(null);
    const [desiredSector, setDesiredSector] = useState(null);

    const [form] = Form.useForm();

    // Fetch profiles with error handling
    const fetchProfiles = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(config.profilesListUrl.replace('{organisation_id}', '1') + `?format=json&page=${page}`);
            const { data } = response;
            setProfiles(data.results);
            setPagination({
                current: page,
                pageSize: 10,
                total: data.count,
            });
        } catch (error) {
            message.error(t('Failed to fetch profiles'));
        } finally {
            setLoading(false);
        }
    };

    // Fetch sectors for dropdowns
    const fetchSectors = async () => {
        try {
            const response = await axios.get(config.sectorUrl);
            setSectors(response.data);
        } catch (error) {
            message.error(t('Failed to fetch sectors'));
        }
    };

    // Fetch job roles for the selected sector
    const fetchJobRoles = async (sectorId, type) => {
        try {
            const response = await axios.get(`${config.jobRolesUrl}&sector_id=${sectorId}`);
            if (type === 'current') {
                setCurrentJobRoles(response.data);
            } else if (type === 'desired') {
                setDesiredJobRoles(response.data);
            }
        } catch (error) {
            message.error(t('Failed to fetch job roles'));
        }
    };

    // Effect hook to fetch profiles and sectors when the component mounts
    useEffect(() => {
        fetchProfiles(pagination.current).catch(error => {
            console.error("Failed to fetch profiles:", error);
            message.error(t('Failed to fetch profiles'));
        });
        fetchSectors(); // Fetch sectors on mount
    }, [pagination.current]);

    // Handle table pagination and call fetchProfiles
    const handleTableChange = async (pagination) => {
        await fetchProfiles(pagination.current).catch(error => {
            console.error("Failed to fetch profiles:", error);
            message.error(t('Failed to fetch profiles'));
        });
    };

    // Handle current role skill gap analysis
    const handleCurrentRoleAnalysis = async (record) => {
        setAnalysisLoading(true); // Start loading indicator
        const formData = new FormData();
        formData.append('current_job_role', record.current_job_role.id);
        formData.append('current_sector', record.current_job_role.sector);
        formData.append('user_id', record.ext_user_id);

        try {
            const result = await axios.post(config.skillsAnalysisUrl, formData);
            navigate('/skills-gap', { state: { data: result.data, analysis: 'current' } });
        } catch (error) {
            message.error(t('Failed to analyze current role skill gap'));
        } finally {
            setAnalysisLoading(false); // Stop loading indicator
        }
    };

    // Handle desired role skill gap analysis
    const handleDesiredRoleAnalysis = async (record) => {
        setAnalysisLoading(true); // Start loading indicator
        const formData = new FormData();
        formData.append('current_job_role', record.current_job_role.id);
        formData.append('current_sector', record.current_job_role.sector);
        formData.append('desired_job_role', record.desired_job_role.id);
        formData.append('desired_sector', record.desired_job_role.sector);
        formData.append('user_id', record.ext_user_id);

        try {
            const result = await axios.post(config.skillsAnalysisUrl, formData);
            navigate('/skills-gap', { state: { data: result.data, analysis: 'desired' } });
        } catch (error) {
            message.error(t('Failed to analyze desired role skill gap'));
        } finally {
            setAnalysisLoading(false); // Stop loading indicator
        }
    };

    // Show modal to update job roles and CV
    const showModal = (record) => {
        setSelectedProfile(record);
        setCurrentSector(record.current_job_role.sector); // Set initial current sector
        setDesiredSector(record.desired_job_role.sector); // Set initial desired sector

        // Fetch job roles for the current and desired sectors
        fetchJobRoles(record.current_job_role.sector, 'current');
        fetchJobRoles(record.desired_job_role.sector, 'desired');

        form.setFieldsValue({
            current_sector: record.current_job_role.sector,
            desired_sector: record.desired_job_role.sector,
            current_job_role: record.current_job_role.id,
            desired_job_role: record.desired_job_role.id,
        });
        setIsModalVisible(true);
    };

    // Handle updating user profile
    const handleUpdateProfile = async (values) => {
        const formData = new FormData();
        formData.append('current_job_role', values.current_job_role);
        formData.append('desired_job_role', values.desired_job_role);

        if (fileList.length > 0) {
            formData.append('cv', fileList[0].originFileObj); // Assume only one file is uploaded
        }

        try {
            // Correctly replace {id} with the selected profile's ID
            const url = config.updateUserProfileUrl.replace('{id}', selectedProfile.id);
            await axios.patch(url, formData);

            message.success(t('Profile updated successfully'));
            setIsModalVisible(false);
            setFileList([]);
            fetchProfiles(pagination.current); // Refresh the profiles
        } catch (error) {
            message.error(t('Failed to update profile'));
        }
    };

    // Handle saving users to Vector DB
    const handleSaveUsersToVectorDB = async () => {
        setLoading(true);
        try {
            await axios.post(config.saveUsersToVectorDBUrl, {});
            message.success(t('Users saved to Vector DB successfully'));
        } catch (error) {
            message.error(t('Failed to save users to Vector DB'));
        } finally {
            setLoading(false);
        }
    };

    // Handle additional files upload immediately after dragging and dropping
    const handleAdditionalFilesUpload = async ({ file }) => { // Removed unused event parameter
        const formData = new FormData();
        formData.append('file', file);
        formData.append('ext_user_id', selectedProfile.ext_user_id);
        formData.append('type', 'UserProfile');
        formData.append('filename', file.name);
        formData.append('content_type', file.type);
        formData.append('size', file.size);

        try {
            await axios.post(config.uploadFilesUrl, formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.uid]: progress,
                    }));
                },
            });

            message.success(`${file.name} uploaded successfully`);
        } catch (error) {
            message.error(`Failed to upload ${file.name}`);
        }
    };

    // Handle file upload changes
    const handleUploadChange = ({ fileList }) => setFileList(fileList);

    // Define the menu for the context menu
    const menu = (record) => (
        <Menu>
            <Menu.Item key="1" onClick={() => showModal(record)} disabled={analysisLoading}>
                {t('Update Profile')}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleCurrentRoleAnalysis(record)} disabled={analysisLoading}>
                {t('Analyse Current Role Skill Gap')}
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleDesiredRoleAnalysis(record)} disabled={analysisLoading}>
                {t('Analyse Desired Role Skill Gap')}
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: t('First Name'),
            dataIndex: 'ext_first_name',
            key: 'ext_first_name',
        },
        {
            title: t('Last Name'),
            dataIndex: 'ext_last_name',
            key: 'ext_last_name',
        },
        {
            title: t('Current Skills Score'),
            dataIndex: 'current_skills_score',
            key: 'current_skills_score',
            render: (score) => <Progress percent={score} showInfo={false} />,
        },
        {
            title: t('Current Job Role'),
            dataIndex: ['current_job_role', 'name'],
            key: 'current_job_role',
        },
        {
            title: t('Desired Job Role'),
            dataIndex: ['desired_job_role', 'name'],
            key: 'desired_job_role',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button icon={<EllipsisOutlined />} disabled={analysisLoading} />
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={loading || analysisLoading}> {/* Show loading spinner when loading or analyzing */}
                <Card
                    title={t('profile_list')}
                    extra={
                        <Button type="primary" icon={<SaveOutlined />} onClick={handleSaveUsersToVectorDB}>
                            {t('Save to Vector DB')}
                        </Button>
                    }
                >
                    <div className="profiles-container">
                        <Table
                            columns={columns}
                            dataSource={profiles}
                            rowKey="id"
                            pagination={pagination}
                            onChange={handleTableChange}
                        />
                    </div>
                </Card>
            </Spin>

            <Modal
                title={t('Update Profile')}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    form.validateFields()
                        .then(values => handleUpdateProfile(values))
                        .catch(info => console.log('Validation Failed:', info));
                }}
                width={600} // Increased modal width
                confirmLoading={analysisLoading} // Disable OK button while loading
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label={t('Current Sector')}
                        name="current_sector"
                        rules={[{ required: true, message: t('Please select current sector') }]}
                    >
                        <Select
                            placeholder={t('Select current sector')}
                            onChange={(value) => {
                                setCurrentSector(value);
                                fetchJobRoles(value, 'current'); // Fetch job roles when sector changes
                                form.setFieldsValue({ current_job_role: null }); // Reset job role selection
                            }}
                            value={currentSector}
                            showSearch
                            optionFilterProp="children"
                            disabled={analysisLoading} // Disable if loading
                        >
                            {sectors.map(sector => (
                                <Option key={sector.id} value={sector.id}>{sector.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('Current Job Role')}
                        name="current_job_role"
                        rules={[{ required: true, message: t('Please select current job role') }]}
                    >
                        <Select
                            placeholder={t('Select current job role')}
                            value={form.getFieldValue('current_job_role')}
                            showSearch
                            optionFilterProp="children"
                            disabled={analysisLoading} // Disable if loading
                        >
                            {currentJobRoles.map(role => (
                                <Option key={role.id} value={role.id}>{role.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('Desired Sector')}
                        name="desired_sector"
                        rules={[{ required: true, message: t('Please select desired sector') }]}
                    >
                        <Select
                            placeholder={t('Select desired sector')}
                            onChange={(value) => {
                                setDesiredSector(value);
                                fetchJobRoles(value, 'desired'); // Fetch job roles when sector changes
                                form.setFieldsValue({ desired_job_role: null }); // Reset job role selection
                            }}
                            value={desiredSector}
                            showSearch
                            optionFilterProp="children"
                            disabled={analysisLoading} // Disable if loading
                        >
                            {sectors.map(sector => (
                                <Option key={sector.id} value={sector.id}>{sector.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('Desired Job Role')}
                        name="desired_job_role"
                        rules={[{ required: true, message: t('Please select desired job role') }]}
                    >
                        <Select
                            placeholder={t('Select desired job role')}
                            value={form.getFieldValue('desired_job_role')}
                            showSearch
                            optionFilterProp="children"
                            disabled={analysisLoading} // Disable if loading
                        >
                            {desiredJobRoles.map(role => (
                                <Option key={role.id} value={role.id}>{role.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('Upload CV')}
                        name="cv"
                    >
                        <Dragger
                            fileList={fileList}
                            onChange={handleUploadChange}
                            beforeUpload={() => false} // Prevent automatic upload
                            accept=".pdf"
                            maxCount={1}
                            disabled={analysisLoading} // Disable if loading
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                            <p className="ant-upload-hint">{t('Support for a single upload. Only PDF files are allowed.')}</p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item
                        label={t('Upload Additional Files')}
                        name="additional_files"
                    >
                        <Dragger
                            fileList={additionalFiles}
                            beforeUpload={(file) => {
                                handleAdditionalFilesUpload({ file }); // Removed unused onProgress event
                                return false; // Prevent automatic upload
                            }}
                            onChange={({ fileList }) => setAdditionalFiles(fileList)}
                            multiple
                            disabled={analysisLoading} // Disable if loading
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                            <p className="ant-upload-hint">{t('Support for multiple uploads. All file types are allowed.')}</p>
                        </Dragger>
                        {additionalFiles.map(file => (
                            <Progress
                                key={file.uid}
                                percent={uploadProgress[file.uid] || 0}
                                status={uploadProgress[file.uid] === 100 ? 'success' : 'active'}
                            />
                        ))}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default UserDirectory;
