import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Timeline, Button, message, Spin } from 'antd';
import config from '../config'; // API config
import { useTranslation } from 'react-i18next'; // For localization
import moment from 'moment';
import '../styles/App.scss';
import '../styles/ReleaseLog.scss'; // Import custom styles

// Helper to mark releases as seen in localStorage
const markAsSeen = (id) => {
    let seenReleases = JSON.parse(localStorage.getItem('seenReleases')) || [];
    seenReleases.push(id);
    localStorage.setItem('seenReleases', JSON.stringify(seenReleases));
};

// Helper to check if a release has been seen
const isSeen = (id) => {
    let seenReleases = JSON.parse(localStorage.getItem('seenReleases')) || [];
    return seenReleases.includes(id);
};

const ReleaseLogTimeline = () => {
    const [releaseLogs, setReleaseLogs] = useState([]);
    const [unseenLogs, setUnseenLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation(); // For i18n translation

    // Fetch release logs from API
    useEffect(() => {
        const fetchReleaseLogs = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${config.releaseLogUrl}`);
                const logs = response.data;
                setReleaseLogs(logs);

                // Filter unseen logs
                const unseen = logs.filter(log => !isSeen(log.id));
                setUnseenLogs(unseen);
            } catch (error) {
                message.error(t('release_log.fetch_failed'));
            } finally {
                setLoading(false);
            }
        };

        fetchReleaseLogs();
    }, [t]);

    // Handle marking release as seen
    const handleMarkAsSeen = (logId) => {
        markAsSeen(logId);
        setUnseenLogs(unseenLogs.filter(log => log.id !== logId));
        message.success(t('release_log.marked_as_seen'));
    };

    return (
        <Spin spinning={loading}>
            <div className="release-log-container">
                <h2>{t('release_log.title')}</h2>
                <Timeline>
                    {releaseLogs.map(log => (
                        <Timeline.Item
                            key={log.id}
                            color={log.release_date > moment().format('YYYY-MM-DD') ? 'gray' : 'green'}
                        >
                            <div className="release-log-item">
                                <strong>{log.title}</strong> - {log.version} <br />
                                {log.description}
                                <br />
                                <em>{t('release_log.release_date')}: {moment(log.release_date).format('MMMM Do, YYYY')}</em>
                                {log.release_date > moment().format('YYYY-MM-DD') && (
                                    <span className="upcoming-release">
                                        {t('release_log.upcoming_release')}
                                    </span>
                                )}
                            </div>
                            {!isSeen(log.id) && (
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => handleMarkAsSeen(log.id)}
                                    style={{ marginTop: '10px' }}
                                >
                                    {t('release_log.mark_as_seen')}
                                </Button>
                            )}
                        </Timeline.Item>
                    ))}
                </Timeline>
            </div>
        </Spin>
    );
};

export default ReleaseLogTimeline;
