import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Button, Spin, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import RecruitmentList from './RecruitmentList';
import RecruitmentForm from './RecruitmentForm';
import config from '../../config';
import '../../styles/RecruitmentManager.scss';

const RecruitmentManager = ({ profile }) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchRecruitmentRecords();
    }, []);

    const fetchRecruitmentRecords = async () => {
        setLoading(true);
        try {
            const response = await axios.get(config.recruitmentListUrl);
            setData(response.data);
        } catch (error) {
            message.error(t('recruitment.fetch_error'));
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (formData) => {
        try {
            await axios.post(config.addRecruitmentUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            message.success(t('recruitment.create_success'));
            fetchRecruitmentRecords();
            setIsModalVisible(false);
        } catch (error) {
            message.error(t('recruitment.create_error'));
        }
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    return (
        <Spin spinning={loading}>
            <Card title={t('recruitment.recruitment_listing')}>
                <div className="recruitment-manager">
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
                        <Button type="primary" icon={<PlusOutlined/>} onClick={showModal}>
                            {t('recruitment.create_record')}
                        </Button>
                    </div>
                        <RecruitmentList data={data}/>
                        {isModalVisible && (
                            <RecruitmentForm
                                visible={isModalVisible}
                                onCancel={hideModal}
                                onSubmit={handleFormSubmit}  // Pass the form submission handler
                                user_id={profile.id}
                            />
                        )}
                </div>
            </Card>
        </Spin>
);
};

export default RecruitmentManager;
