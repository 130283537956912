import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, InputNumber, Space, Row, Col, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from "../../config";

const { Option } = Select;

const RubricForm = ({ rubric, onSubmit }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [rubricTypes, setRubricTypes] = useState([]);  // State for rubric types

    // Fetch rubric types when component is mounted
    useEffect(() => {
        axios.get(`${config.listTypeUrl}?category=rubrics_type`)
            .then(response => {
                setRubricTypes(response.data);
            })
            .catch(() => {
                message.error(t('rubrics.rubric_type_fetch_error'));
            });
    }, [t]);

    // Pre-fill the form with existing rubric data if editing
    useEffect(() => {
        if (rubric) {
            form.setFieldsValue({
                title: rubric.title,
                type: rubric.type.id,
                weightage: rubric.weightage,
                metrics: rubric.metrics.map((metric) => ({
                    id: metric.id,
                    name: metric.name,
                    description: metric.description,
                    score: metric.score,
                    comments: metric.comments
                }))
            });
        } else {
            form.resetFields();
        }
    }, [rubric, form]);

    const handleSubmit = (values) => {
        const rubricData = {
            title: values.title,
            type_id: values.type,  // Send only the type ID
            weightage: values.weightage,
            metrics: values.metrics.map(metric => ({
                id: metric.id || null,  // Keep existing ID or mark as new with null
                name: metric.name,
                description: metric.description,
                score: metric.score,
                comments: metric.comments
            }))
        };
        onSubmit(rubricData);
    };

    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
                name="title"
                label={t('rubrics.rubric_title')}
                rules={[{ required: true, message: t('rubrics.rubric_title_required') }]}
            >
                <Input className="limited-width" placeholder={t('rubrics.rubric_title_placeholder')} />
            </Form.Item>

            <Form.Item
                name="type"
                label={t('rubrics.rubric_type')}
                rules={[{ required: true, message: t('rubrics.rubric_type_required') }]}
            >
                <Select className="limited-width" placeholder={t('rubrics.select_rubric_type')}>
                    {rubricTypes.map(type => (
                        <Option key={type.id} value={type.id}>{type.value}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="weightage"
                label={t('rubrics.weightage')}
                rules={[{ required: true, message: t('rubrics.weightage_required') }]}
            >
                <InputNumber className="limited-width" min={0} max={100} placeholder={t('rubrics.enter_weightage')} style={{ width: '100%' }} />
            </Form.Item>

            {/* Dynamic list of metrics */}
            <Form.List name="metrics">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            fieldKey={[fieldKey, 'name']}
                                            label={t('rubrics.metric_name')}
                                            rules={[{ required: true, message: t('rubrics.metric_name_required') }]}
                                        >
                                            <Input placeholder={t('rubrics.metric_name_placeholder')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'score']}
                                            fieldKey={[fieldKey, 'score']}
                                            label={t('rubrics.metric_score')}
                                            rules={[{ required: true, message: t('rubrics.metric_score_required') }]}
                                        >
                                            <InputNumber min={0} max={100} placeholder={t('rubrics.metric_score_placeholder')} style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'description']}
                                            fieldKey={[fieldKey, 'description']}
                                            label={t('rubrics.metric_description')}
                                            rules={[{ required: true, message: t('rubrics.metric_description_required') }]}
                                        >
                                            <Input.TextArea placeholder={t('rubrics.metric_description_placeholder')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'comments']}
                                            fieldKey={[fieldKey, 'comments']}
                                            label={t('rubrics.metric_comments')}
                                        >
                                            <Input.TextArea placeholder={t('rubrics.metric_comments_placeholder')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <Button
                                            type="danger"
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => remove(name)}
                                        />
                                    </Col>
                                </Row>
                            </Space>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('rubrics.add_metric')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {t('submit')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RubricForm;
