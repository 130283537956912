import React, { useEffect, useState } from 'react';
import { Select, Spin, message } from 'antd';
import axios from 'axios';
import config from '../config';

const { Option, OptGroup } = Select;

const JobRoleSelect = ({ value, onChange }) => {
    const [sectors, setSectors] = useState([]);
    const [loadingSectors, setLoadingSectors] = useState(true);
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => {
        const fetchSectors = async () => {
            setLoadingSectors(true);
            try {
                const response = await axios.get(config.sectorListUrl);
                setSectors(response.data);
            } catch (error) {
                message.error('Failed to fetch sectors.');
            } finally {
                setLoadingSectors(false);
            }
        };

        fetchSectors();
    }, []);

    const handleChange = (newValue) => {
        // We only want to allow a single value, so update the input value accordingly
        const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
        setInputValue(singleValue);
        if (onChange) {
            onChange(singleValue);
        }
    };

    return (
        <Select
            mode="tags"
            value={inputValue ? [inputValue] : []}
            onChange={handleChange}
            placeholder="Select or type a job role"
            style={{ width: '100%' }}
            tokenSeparators={[',']}
            maxTagCount={1} // Ensures only one tag is displayed
            notFoundContent={loadingSectors ? <Spin size="small" /> : null}
        >
            {sectors.length > 0 &&
                sectors.map((sector) => (
                    <OptGroup key={sector.id} label={sector.name}>
                        {sector.job_roles.map((role) => (
                            <Option key={role.id} value={role.name}>
                                {role.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
        </Select>
    );
};

export default JobRoleSelect;
