import React, { useState } from 'react';
import { Modal, Form, Button, DatePicker, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import JobRoleSelect from '../../components/JobRoleSelect';
import FileUpload from '../../components/FileUpload';

const RecruitmentForm = ({ visible, onCancel, onSubmit, user_id }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [jobDescriptionFiles, setJobDescriptionFiles] = useState([]);
    const [cvFiles, setCvFiles] = useState([]);

    // Form submission with file handling
    const handleFinish = async () => {
        try {
            const values = await form.validateFields();  // Get all form field values
            const formData = new FormData();  // Create FormData object

            // Add the regular form fields
            formData.append('job_role', values.jobRole);
            formData.append('start_date', values.startDate ? values.startDate.format('YYYY-MM-DD') : '');
            formData.append('end_date', values.endDate ? values.endDate.format('YYYY-MM-DD') : '');

            // Append job description file (single file)
            if (jobDescriptionFiles.length > 0) {
                formData.append('job_description', jobDescriptionFiles[0]);  // Assuming single file upload
            }

            // Append CV files as an array (multiple files)
            cvFiles.forEach((fileId) => {
                formData.append('cv_upload', fileId);  // Append each CV file as part of the 'cv_upload[]' array
            });

            // Log FormData contents for debugging
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }

            // Try submitting the form data
            const response = await onSubmit(formData);

            // Only reset the form and close the modal if the submission was successful
            if (response && response.status === 201) {
                form.resetFields();
                setJobDescriptionFiles([]);
                setCvFiles([]);
                message.success('Form submitted successfully!');
                onCancel();  // Close the modal
            } else {
                message.error('Form submission failed, please correct the errors.');
            }
        } catch (errorInfo) {
            console.log('Form validation failed:', errorInfo);
        }
    };

    // Handle job description file upload success
    const handleJobDescriptionUpload = (fileId) => {
        setJobDescriptionFiles([fileId]);  // Save the file ID (single upload for job description)
        form.setFieldsValue({ jobDescription: fileId });  // Set the jobDescription form field with a value
    };

    // Handle CV file upload success
    const handleCvUpload = (fileId) => {
        setCvFiles((prevFiles) => [...prevFiles, fileId]);  // Add the new file ID to the list of CV files
        form.setFieldsValue({ cvUpload: [...cvFiles, fileId] });  // Update the cvUpload form field with the list of file IDs
    };

    return (
        <Modal
            title={t('recruitment.create_record')}
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="jobRole"
                    label={t('recruitment.job_role')}
                    rules={[{ required: true, message: t('recruitment.job_role_required') }]}
                >
                    <JobRoleSelect />
                </Form.Item>

                <Form.Item
                    name="jobDescription"
                    label={t('recruitment.job_description')}
                    rules={[{ required: true, message: t('recruitment.job_description_required') }]}
                >
                    <FileUpload
                        accept=".txt,.doc,.docx,.pdf"
                        multiple={false}
                        directory={false}
                        dragArea={true}
                        user_id={user_id}
                        purpose="job_description"
                        onUploadSuccess={handleJobDescriptionUpload}  // Pass the file ID
                        onUploadError={(error) => message.error(`Job description upload failed: ${error.message}`)}
                    />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="startDate"
                            label={t('recruitment.start_date')}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="endDate"
                            label={t('recruitment.end_date')}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="cvUpload"
                    label={t('recruitment.cv_upload')}
                >
                    <FileUpload
                        accept=".pdf,.doc,.docx"
                        multiple={true}  // Allow multiple files to be uploaded
                        directory={false}
                        dragArea={true}
                        user_id={user_id}
                        purpose="cv"
                        onUploadSuccess={handleCvUpload}  // Pass the file ID
                        onUploadError={(error) => message.error(`CV upload failed: ${error.message}`)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={handleFinish}>
                        {t('create')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RecruitmentForm;
